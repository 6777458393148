<template>
  <div class="charge flexbox vertical">
    <header class="page-header">
      <van-icon name="arrow-left" @click="$router.go(-1)" />
      <span>充值</span>
      <span></span>
    </header>
    <van-form @submit="onSubmit">

    <main class="page-body flex-fill">
      <section class="card">
        <div class="label">充值金额</div>
        <div class="flexbox space-bt tags">
          <div
            class="amount-tag"
            :class="{ active: selected === index }"
            @click="(selected = index), (amountInput = '')"
            v-for="(item, index) in amountOptions"
            :key="index"
          >
            {{ item }}
          </div>
        </div>
        <div class="label">其他金额</div>
        <div class="flexbox align-center">
          <span>￥</span>
          <van-field
            class="border-input"
            v-model="amountInput"
            @input="selected = ''"
          ></van-field>
        </div>
      </section>
      <section class="card">
        <div class="label">支付方式</div>
        <van-radio-group v-model="paymentOption">
          <van-radio name="1" label-position="left" class="payment-option">
            <van-icon name="wechat" />
            微信支付
          </van-radio>
          <van-radio name="2" label-position="left" class="payment-option">
            <van-icon name="alipay" />
            支付宝支付
          </van-radio>
          <van-radio name="3" label-position="left" class="payment-option">
            <van-icon name="card" />
            银联支付
          </van-radio>
        </van-radio-group>
      </section>
    </main>
    <footer class="page-footer">
      <div>充值金额</div>
      <div class="amount">￥{{ amount }}</div>
      <van-button type="info">充值</van-button>
    </footer>
    </van-form>  
    <div>
    <div>{{outlog}}</div>
  </div>
  </div>

</template>
<script>
import { submitCharge } from '@/api/money.js';
import{ BASE_ROOT_URL } from '@/api/index.js'
export default {
  data() {
    return {
      selected: 0,
      amountOptions: [0.01, 200, 500, 1000],
      paymentOption: '1',
      amountInput: '',
      outlog: '',
    };
  },
  computed: {
    amount() {
      return this.amountInput || this.amountOptions[this.selected] || 0;
    },
  },
  methods: {

    //环境检测
    checkEnv() {

      //平台、设备和操作系统 
      var system = {
        win: false,
        mac: false,
        xll: false,
        ipad: false,
      };
      //检测平台 
      var p = navigator.platform;
      system.win = p.indexOf("Win") == 0;
      system.mac = p.indexOf("Mac") == 0;
      system.x11 = (p == "X11") || (p.indexOf("Linux") == 0);
      system.ipad = (navigator.userAgent.match(/iPad/i) != null) ? true : false;

      if (system.win || system.mac || system.xll || system.ipad) {
        //alert("在PC端上打开的");
        return "pc";
      } else {
        var ua = navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
          //alert("在手机端微信上打开的");
          return "wx";
        } else {
          //alert("在手机上非微信上打开的");
          return "mb"
        }
      }
    },
    onSubmit() {

      //根据当前环境做判断
      var env = this.checkEnv();

      //var that = this;
      //this.$toast.fail('env=' + env);

      if (this.paymentOption == '1') {
        submitCharge({
          amount: this.amount ,
          desc: '微信充值',
          env: env,
          cb: BASE_ROOT_URL + '/ChargeResult',//支付完成回调，手机浏览器支付需要用到
        }).then(res => {

          //that.outlog =JSON.stringify(BASE_ROOT_URL + '/ChargeResult') ;
          if (env == 'pc') {
            this.img = 'data:;base64,' + res.data
          }

          if (env == 'wx') {
            if (res != null) {
              const pay_params = res
              if (typeof WeixinJSBridge === "undefined") {
                if (document.addEventListener) {
                  document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady(pay_params), false);
                } else if (document.attachEvent) {
                  document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady(pay_params));
                  document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady(pay_params));
                }
              } else {
                this.onBridgeReady(pay_params);
              }
            } else {
              this.$toast.fail('微信支付调起失败！');
            }
          }

          if (env == 'mb' && res.Result) {
            //手机环境浏览器，跳转微信充值付款界面
            window.location.href = res.Info;
          } else {
            this.$toast.fail(res.Info);
          }
        });
      }

    },
    onBridgeReady:function(params) {
      var that = this;
      window.WeixinJSBridge.invoke(
        'getBrandWCPayRequest', {
        "appId": params.appId,  //公众号名称，由商户传入
        "timeStamp": params.timeStamp, //支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
        "nonceStr": params.nonceStr,  //支付签名随机串，不长于 32 位
        "package": params.package,//统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
        "signType": 'MD5',  //签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
        "paySign": params.paySign, //支付签名
      },
        function (res) {
          if (res.err_msg === "get_brand_wcpay_request:ok") {

            that.$toast.success('支付成功！');
            that.$router.push({ path: '/transactions' });

          } else if (res.err_msg === "get_brand_wcpay_request:fail") {
            that.$toast.fail('支付失败！');
          } else if (res.err_msg === "get_brand_wcpay_request:cancel") {
            that.$toast.fail('取消支付！');
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/_var';
.page-header {
  border-bottom: 1px solid #e0e0e0;
}
.card {
  margin-bottom: rem(10px);
  background-color: #fff;
  padding: rem(40px);
  font-size: 16px;
}
.label {
  font-size: 16px;
  margin-bottom: rem(60px);
}
.tags {
  margin-bottom: rem(60px);
}
.amount-tag {
  width: rem(152px);
  height: rem(50px);
  line-height: rem(50px);
  text-align: center;
  background-color: #f7f7fc;
  border-radius: rem(10px);
  color: #9c9bb4;
  &.active {
    background-color: $theme-color;
    color: #fff;
  }
}
.payment-option {
  background: #f7f7fc;
  border-radius: 50px;
  display: flex;
  align-items: center;
  height: rem(100px);
  line-height: rem(100px);
  padding: 0 rem(40px);
  justify-content: space-between;
  border: 2px solid transparent;
  color: #9c9bb4;
  &[aria-checked='true'] {
    border-color: #5c57bb;
    .van-icon {
      color: $theme-color;
    }
  }
  & + .payment-option {
    margin-top: rem(40px);
  }
  .van-icon {
    font-size: 18px;
  }
}
.page-footer {
  height: rem(118px);
  line-height: rem(118px);
  display: flex;
  align-items: center;
  padding: 0 rem(40px);
  background-color: #fff;
  .van-button {
    margin-left: auto;
    width: rem(240px);
    border-radius: rem(25px);
  }
  .amount {
    color: #dd1b1b;
    margin-left: rem(40px);
    font-size: 18px;
    font-weight: bold;
  }
}
.border-input {
  border-bottom: 1px solid #e0e0e0;
}
/deep/ .van-radio__label {
  display: flex;
  align-items: center;
  color: #9c9bb4;
  .van-icon {
    font-size: 24px;
    margin-right: rem(16px);
  }
}
</style>
